*,
*:before,
*:after {
  box-sizing: border-box;
}
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:600,700,500|Playfair+Display:700,900|Roboto:400|Montserrat:600,400,700,500');
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:600,500,700|Playfair+Display:900,700|Roboto:400|Montserrat:600,500,400,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:700,500,600|Playfair+Display:700,900|Roboto:400|Montserrat:700,500,400,600');
@import url('https://fonts.googleapis.com/css?family=Poppins:700,500,600|Playfair+Display:700,900|Roboto:400|Montserrat:700,500,400,600');
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Poppins:700,500,600|Playfair+Display:700,900|Roboto:400|Montserrat:700,500,400,600');

body,
html {
  max-width: 100vw;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  /*Fallback if gradeints don't work */

  overflow-x: hidden;

  background: #9b59b6;

  background: radial-gradient(at center, #3498db, #9b59b6);
}

@keyframes float {
  0% {
    filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0 25px 15px 0px rgba(0, 0, 0, 0.2));

    transform: translatey(-20px);
  }

  100% {
    filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
    transform: translatey(0px);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --vivid-tangerine: rgba(255, 163, 137, 1);
  --awesome-blue: #0633bd;
}

$color: #985bb7; // purple
$highlight: #6878c8; // blue
$alternate: #e49996; // orangish
$pad: 10px;
