.footer {
  position: relative;
  left: 0;
  text-align: center;
  bottom: 0;

  height: 3rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  width: 100%;
}
.bg-image {
  background-image: url("https://i.imgur.com/Tuwt0do.png");
  background-size: 100% 100%;
  width: 100vw;
  background-repeat: no-repeat;
  color: white;
  position: relative;
  bottom: 0;
  z-index: -1;
  filter: blur(2.5px);
}
